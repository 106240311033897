/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "../../static/css/global.css"
import "../../static/css/structure.css"
import "../../static/css/hr.css"
import "../../static/css/rs-settings.css"
import "../../static/css/custom_hr.css"
import "../../static/css/custom.css"

const Layout = ({ title, children, show_slider, show_bread }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="Wrapper">
      <Header
        title={title}
        show_bread={show_bread}
        show_slider={show_slider}
        siteTitle={data.site.siteMetadata.title}
      />

      <div id="Content">
        <div className={"content_wrapper clearfix"}>
          <div className={"sections_group"}>
            <div className={"entry-content"}>
              <main>{children}</main>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  show_slider: PropTypes.bool,
  show_bread: PropTypes.bool,
}
Layout.defaultProps = {
  show_slider: false,
  show_bread: false,
}

export default Layout
