import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import Menu from "./menu"
import Slider from "./slider"
import Breadcrumb from "./breadcrumb"

const Header = ({ title, siteTitle, show_slider, show_bread }) => (
  <div className={"bg-parallax"} id={"Header_wrapper"}>
    <header id={"Header"}>
      <div id={"Top_bar"}>
        <div className={"container"}>
          <div className={"column one"}>
            <div className={"top_bar_left clearfix"}></div>
            <Logo />
            <Menu title={title} />
            <div className={"secondary_menu_wrapper"}></div>
            <div className={"banner_wrapper"}></div>
          </div>
        </div>
      </div>
    </header>
    {show_slider ? <Slider /> : null}
    {show_bread ? <Breadcrumb title_page={title} /> : null}
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  show_slider: PropTypes.bool,
  show_bread: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  show_slider: false,
  show_bread: false,
}

export default Header
