import React from "react"
import PropTypes from "prop-types"
const Breadcrumb = ({title_page}) => (
  <div
    id="Subheader"
    style={{padding:'100px 0', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',backgroundImage: 'url(/images/acerca_de.jpg)'}}
     >
    <div className={"container"}>
      <div className={"column one"}>
        <h1 className={"title"} style={{marginTop: '0'}}>
        {title_page}
        </h1>
      </div>
    </div>
  </div>
)
Breadcrumb.propTypes = {
  title_page: PropTypes.string,
}
Breadcrumb.defaultProps = {
  title_page: 'false',
}




export default Breadcrumb
