import { Link } from "gatsby"
import React from "react"
import e_logo from "../../static/images/apalache.svg" 

const Logo = () => (
  <div className={"logo"}>
    <Link
      style={{
        width: '90px',
        height: '90px',
      }}
      id={"logo"}
      to="/"
    >
      <img className={'logo-main scale-with-grid'} src={e_logo} alt="Logo" />
      <img className={'logo-sticky scale-with-grid'} src={e_logo} alt="Logo" />
      <img className={'logo-mobile scale-with-grid'} src={e_logo} alt="Logo" />
    </Link>
  </div>
)

export default Logo
