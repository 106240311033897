/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../../static/images/favicon.ico"
function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet title={title} titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <meta charSet="utf-8" />
      {/* <title>Apalache Análisis</title> */}
      <meta name="description" content={metaDescription} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link rel="icon" href={favicon} />
      <link
        rel="stylesheet"
        href="http://fonts.googleapis.com/css?family=Roboto:100,300,400,400italic,700"
      />
      <link
        rel="stylesheet"
        href="http://fonts.googleapis.com/css?family=Patua+One:100,300,400,400italic,700"
      />
      <link
        rel="stylesheet"
        href="http://fonts.googleapis.com/css?family=Lato:300,300italic,400,400italic,700,700italic"
      />

      <body
        className={
          "home page nice-scroll nice-scroll-on template-slider color-custom style-simple layout-full-width mobile-tb-hide button-flat no-content-padding header-classic minimalist-header-no sticky-header sticky-white ab-hide subheader-both-center menu-line-below-80 menuo-right menuo-no-borders footer-sliding footer-copy-center "
        }
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
