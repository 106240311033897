
import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer id="Footer" className={"clearfix"}>
    <div
      className={"widgets_wrapper"}
      style={{
        padding: "20px 0 0",
      }}
    >
      <div className={"container"}>
        <div className={"column one-fourth"}>
          <aside className={"widget widget_text"}>
            <div className={"textwidget"}>
              <div
                className={
                  "image_frame image_item no_link scale-with-grid alignnone no_border"
                }
              >
                <div className={"image_wrapper"}>
                  <img
                    className={"scale-with-grid"}
                    src={"/images/apalache.svg"}
                    alt="home_hr_footer_logo"
                    width="90"
                    height="59"
                  />
                </div>
              </div>
              <hr
                className={"no_line"}
                style={{
                  margin: "20PX auto 20px",
                }}
              />
              <p>
                Valuamos instrumentos de capital del mercado mexicano, público y
                privado.
              </p>
            </div>
          </aside>
        </div>
        <div className={"column one-fourth"}>
          <aside className={"widget widget_text"}>
            <h4>Links Destacados</h4>
            <div className={"textwidget"}>
              <ul
                style={{
                  lineHeight: "32px",
                }}
              >
                <li>
                  <i className={"icon-layout"}></i>
                  <a rel="noreferrer" target="_blank" href="http://www.apalache.mx/">
                    Apalache Estructuración
                  </a>
                </li>

                <li>
                  <i className={"icon-layout"}></i>
                  <Link to="/publicaciones">
                    Publicaciones
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
        <div className={"column one-fourth"}>
          <aside className={"widget widget_text"}>
            <h4>Contacto</h4>
            <div className={"textwidget"}>
              <h5>Ciudad de México</h5>
              <p>
                &nbsp;<i className={"fa fa-map-marker"}></i>&nbsp;&nbsp; Montes
                Urales 424 | Lomas de Chapultepec, Miguel Hidalgo | 11000
                <br />
                <br />
                <i className={"icon-phone"}> +52 (55) 6378-2805</i>
              </p>
              <h5></h5>
              <p>
                <i className={""}></i>
              </p>
            </div>
          </aside>
        </div>
        <div className={"column one-fourth"}>
                <aside className={"widget widget_text"}>
                    <h4> &nbsp;</h4>
                    <div className={"textwidget"}>
                        <h5>Nueva York</h5>
                        <p>&nbsp;<i className={"fa fa-map-marker"}></i>&nbsp;&nbsp; 44 Wall Street | NY City | 10005<br/><br/>
                            <i className={""}> </i>
                        </p>
                        <br/>
                        <p className={"big"}>
                            <i className={"icon-mail"}></i>&nbsp; <a
                                href="mailto:contacto@apalache.mx">contacto@apalache.mx</a>
                        </p>
                    </div>
                </aside>
            </div>
      </div>
    </div>
    <div className={"footer_copy"}>
      <div className={"container"}>
        <div className={"column one"}>
          <div className={"copyright"}>
            &copy; {new Date().getFullYear()} Apalache Análisis - Diseñado y
            generado por{" "}
            <a target="_blank" rel="noreferrer" href="http://www.irdesign.mx/">
              IRDesign
            </a>
          </div>
          <ul className={"social"}></ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
