import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

const jQuery = require("jquery")
const Menu = ({ title }) => {
  const show = e => {
    jQuery("#menu").toggleClass("show")
  }
  return (
    <div className={"menu_wrapper"}>
      <nav id="menu" className={"menu-main-menu-container"}>
        <ul id="menu-main-menu" className={"menu"}>
          <li>
            <Link to="/">
              <span>Inicio</span>
            </Link>
          </li>
          <li
            className={
              title === "Acerca de nosotros" ? "current_page_item" : ""
            }
          >
            <Link to="/acerca-de">
              <span>Acerca de nosotros</span>
            </Link>
          </li>
          <li className={title === "Sponsored Research" ? "current_page_item" : ""}>
            <Link to="/sponsored-research">
              <span>Sponsored Research</span>
            </Link>
          </li>
          <li className={title === "Equipo" ? "current_page_item" : ""}>
            <Link to="/equipo">
              <span>Equipo</span>
            </Link>
          </li>
          <li className={title === "Publicaciones" ? "current_page_item" : ""}>
            <Link to="/publicaciones">
              <span>Publicaciones</span>
            </Link>
          </li>
          <li className={title === "Contacto" ? "current_page_item" : ""}>
            <Link to="/contacto">
              <span>Contacto</span>
            </Link>
          </li>
        </ul>
      </nav>
      <a onClick={e => show(e)} className={"responsive-menu-toggle"} href="#">
        <i className={"icon-menu"}></i>
      </a>
    </div>
  )
}
Menu.propTypes = {
  title: PropTypes.string
}

Menu.defaultProps = {
  title: ``
}

export default Menu
