import React from "react"



const Slider = () => {

  return (
    <div className={"mfn-main-slider"} id="mfn-rev-slider">
      <div
        id="rev_slider_1_2_wrapper"
        className={"rev_slider_wrapper fullwidthbanner-container"}
        style={{
          margin: "0 auto",
          backgroundColor: "transparent",
          padding: "0",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <div
          id="rev_slider_1_2"
          className={"rev_slider fullwidthabanner"}
          data-version="5.2.1"
        >
          <ul>
            <li
              data-index="rs-1"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                alt="Slider"
                src={"/images/home_hr_slider_bg.jpg"}
                title="home_hr_slider_bg"
                width="1920"
                height="859"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className={"rev-slidebg"}
                data-no-retina
              />
              <div
                className={"tp-caption   tp-resizeme"}
                id="slide-1-layer-3"
                data-x="50"
                data-y="center"
                data-voffset="-320"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:800;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;s:300;"
                data-start="500"
                data-responsive_offset="on"
                style={{
                  zIndex: "5",
                }}
              >
                <img
                  src={"/images/home_hr_slider_sep.png"}
                  alt=""
                  width="55"
                  height="50"
                  data-ww="55px"
                  data-hh="50px"
                  data-no-retina
                />
              </div>
              <div
                className={"tp-caption mfnrs_hr_small_dark   tp-resizeme"}
                id="slide-1-layer-4"
                data-x="50"
                data-y="center"
                data-voffset="-190"
                data-width="['auto']"
                data-height="['auto']"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:800;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;s:300;"
                data-start="600"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: "6",
                  fontSize: "43px",
                  whiteSpace: "nowrap",
                }}
              >
                Valuación <br />
                <br />y Análisis <br />
                <br />
                Independiente
              </div>
              <div
                className={"tp-caption mfnrs_hr_large_light   tp-resizeme"}
                id="slide-1-layer-2"
                data-x="50"
                data-y="center"
                data-voffset=""
                data-width="['auto']"
                data-height="['auto']"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:800;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;s:300;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: "7",
                  whiteSpace: "nowrap",
                }}
              >
                Apalache
                <br /> Análisis
              </div>
              <div
                className={"tp-caption mfnrs_hr_large_light tp-resizeme"}
                id=""
                data-x="50"
                data-y="center"
                data-voffset="125px"
                data-width="['auto']"
                data-height="['auto']"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:800;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;s:300;"
                data-start="800"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: "7",
                  whiteSpace: "nowrap",
                }}
              >
                <a
                  target="_blank"
                  className={"button button_theme button_js"}
                  id="slider-download"
                  rel="noreferrer"
                  href="https://investorcloud.s3.amazonaws.com/apalache/Apalache-Analisis.pdf"
                >
                  Leer más
                </a>
              </div>
            </li>
          </ul>
          <div
            className={"tp-bannertimer tp-bottom"}
            style={{
              visibility: "hidden",
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Slider
